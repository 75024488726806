import React, {useState, useEffect, useCallback} from 'react';
import {Helmet} from "react-helmet"
import './App.css';

const TicketInfo = ({ eventData }) => {
    const groupedEvents = groupEventsByContentName(eventData);
    const maxDisplay = 10; // 1つのイベントに対して表示する時間帯の最大数

    return (
        <div className="ticket-info-container">
            {groupedEvents.map((group, index) => (
                <div key={index} className="event-info">
                    <img src={group.image_url} alt="イベント画像" className="event-image"/>
                    <div className="event-content-wrapper">
                        <h2 className="ticket-info-header">
                            <budoux-ja>{group.content_name}</budoux-ja>
                        </h2>
                        <div className="event-details">
                            {group.start_time.length > 0 ? (
                                group.start_time.slice(0, maxDisplay).map((time, idx) => (
                                    <div key={idx}
                                         className={`event-square ${group.ticket_stock[idx] === '0' ? 'zero-stock' : ''}`}>
                                        <div className="event-time">{time}</div>
                                        <div className="event-ticket">残り{group.ticket_stock[idx]}枚</div>
                                    </div>
                                ))
                            ) : (
                                <div className="no-events-message">
                                    <budoux-ja>本日の公演は終了しました</budoux-ja>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const groupEventsByContentName = (eventData) => {
    const groupedEvents = [];
    const currentDate = new Date();
    // 時間を18時間すすめたい
    // currentDate.setHours(currentDate.getHours() - 20);

    Object.keys(eventData.date).forEach(date => {
        eventData.date[date].events.forEach(event => {
            // 過去のイベントをフィルタリング
            const eventTime = new Date(`${date} ${event.start_time}`);
            let isFutureEvent = eventTime > currentDate;

            const existingGroup = groupedEvents.find(group => group.content_name === event.name);
            if (existingGroup) {
                if (isFutureEvent) {
                    existingGroup.start_time.push(event.start_time);
                    existingGroup.ticket_stock.push(event.ticket_stock);
                }
            } else {
                if (isFutureEvent) {
                    groupedEvents.push({
                        content_name: event.name,
                        image_url: event.image_url,
                        start_time: [event.start_time],
                        ticket_stock: [event.ticket_stock]
                    });
                } else {
                    // すべてのイベントが過去のものである場合の処理
                    groupedEvents.push({
                        content_name: event.name,
                        image_url: event.image_url,
                        start_time: [],
                        ticket_stock: []
                    });
                }
            }
        });
    });

    return groupedEvents;
};

const App = () => {
    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    };

    const initialShopId = getQueryParam('shopId') || '106'; // デフォルトは株式会社SCRAP
    const limit = getQueryParam('limit') || '3'; // 1ページあたりの表示数（デフォルトは3）
    const switchInterval = getQueryParam('updateTime') || '5000'; // ページ切り替えの間隔（デフォルトは5秒）

    const [jsonData, setJsonData] = useState(null);
    const [shopId] = useState(initialShopId);
    const [shopName, setShopName] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const today = new Date();
    const formattedToday = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;

    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(
                `https://scrapticket.jp/api/v2/events?shop_id=${shopId}&event_start_date=${formattedToday}&event_end_date=${formattedToday}&display_type=content_main&limit=30`
            );
            const data = await response.json();
            setJsonData(data);
            setShopName(data.contents[0].shop_name);
            setTotalPages(Math.ceil(data.content_count / limit));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // eslint-disable-next-line
    }, [shopId, formattedToday]);

    useEffect(() => {
        const switchPageTimer = setInterval(() => {
            setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
        }, switchInterval);

        fetchData();

        return () => {
            clearInterval(switchPageTimer);
        };
    }, [shopId, fetchData, switchInterval, totalPages]);

    useEffect(() => {
        const ticketInfoUpdateTimer = setInterval(() => {
            fetchData();
            console.log('データ更新');
        },  60 * 1000);

        return () => {
            clearInterval(ticketInfoUpdateTimer);
        };
    }, [fetchData]);

    return (
        <div>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <script src="https://unpkg.com/budoux/bundle/budoux-ja.min.js"></script>
            </Helmet>
            <h1 className="header">{formattedToday} {shopName}チケット情報</h1>
            {jsonData &&
                jsonData.contents
                    .slice(currentPage * limit, (currentPage + 1) * limit)
                    .map((eventData, index) => (
                        <TicketInfo key={index} eventData={eventData}/>
                    ))}
            <div className="progress-bar-container">
                <div
                    className="progress-bar"
                    style={{
                        width: `${((currentPage + 1) % totalPages) * (100 / totalPages)}%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default App;